import $ from 'jquery'
import 'select2'

function formatEmployee (employee) {
	if (employee.loading) {
		return employee.full_name;
	}

	var $container = $(`
		<div class="grid">
			<div class="grid__item medium__up one-half">
				<h5 class="member-name">${employee.full_name}</h5>
				<div class="meta-data">
					<div><span class="person-number">${employee.job_title}</span></div>
				</div>
			</div>
			<div class="grid__item medium__up one-half">
				<div class="meta-data">
					<div><span class="person-number">${employee.email}</span></div>
					<div><span class="person-number">${employee.department}</span></div>
					<div><span class="person-number">${employee.location}</span></div>
				</div>
			</div>
		</div>`
		);

	return $container;
}

function formatEmployeeSelection(object) {
	return object.description;
}

function initializeSelects() {

	$('.js-employee-search').select2({
		placeholder: 'Search for Employee...',
		ajax: {
			url: '/employees',
			dataType: 'json',
			width: 'style',
			data: function (params) {
				var query = {
					q: params.term,
					page: params.page || 1,
					per_page: 10
				}

				// Query parameters will be ?q=[term]&page=[page]
				return query;
			},
			processResults: function (data, params) {
				// parse the results into the format expected by Select2
				// since we are using custom formatting functions we do not need to
				// alter the remote JSON data, except to indicate that infinite
				// scrolling can be used
				params.page = params.page || 1;

				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.pagination.count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 2,
		templateResult: formatEmployee,
		templateSelection: formatEmployeeSelection
	})

  if($('#selected_employee_name').val()) {
  	setTimeout(function() {
  		$('#selected_employee_name').siblings('.select2-container--default').find('.select2-selection__rendered').removeAttr('title');
    	$('#selected_employee_name').siblings('.select2-container--default').find('.select2-selection__rendered').text($("#selected_employee_name").val());
  	}, 10)
  	
  }
}

window.addEventListener('turbolinks:load', initializeSelects)