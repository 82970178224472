// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//global.Rails = require("@rails/ujs")
import Rails from "@rails/ujs"
// import html2canvas from 'html2canvas';

global.Rails = Rails
global.Rails.start()
require("turbolinks").start()
//require("@rails/activestorage").start()

require("chartkick")
require("chart.js")
require("channels")
require( 'datatables.net-dt' );

window.jQuery = window.$ = require("jquery");
// window.html2canvas = html2canvas;
require("select2")
require("jquery-ui")
require('jquery-plugins/stretchy-pants')
require('jquery-plugins/reveal')
require('jquery-plugins/hug-bottom')
require("globals")

require("globals/quicksight")
require("globals/auto-save")
require("globals/reports")
require("globals/delete_confirmation")
require("globals/report_requests")
require("globals/member_selector")
require("globals/provider_selector")
require("globals/survey_acknowledgements")
require("globals/employee_selector")
require("globals/employee_detail_selector")
require("globals/admission_episodes")
require("globals/sort-radios")
require("globals/pace_lookup")
require("globals/library_messages")
require("globals/message_batches")
require("globals/supervision_sessions")
require("globals/site_orientations")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
