

jQuery.fn.preventDoubleSubmit = function() {
  // i tried .live('submit') but it didn't work
  jQuery(this).each(function(index,f){
    f.doubleSubmitPrevented = true;
  });

  jQuery(this).submit( function() {
    if (this.beenSubmitted)
      {
        return false;
      }
      else
      {
        this.beenSubmitted = true;
      }
  });

};

Application = {
  showFlash: function(message, type){
    if(type===undefined) { type = 'notice' }
    //Application.hideFlash()
    $("body").append('<div class="dynamic-flash flash-' + type + '">' + message + '<span class="dismiss-flash">&times;</span></div>')
    setTimeout(function(){ $(".dynamic-flash").addClass("visible"); }, 10);
    setTimeout(Application.autoHideFlash, 2000);
    $(".dismiss-flash").click(Application.hideFlash);
  },
  autoHideFlash: function() {
    var elementsToRemove = $(".dynamic-flash").not(".flash-perm").removeClass("visible")
    setTimeout(function(){ elementsToRemove.remove() }, 1000)
  },
  hideFlash: function(){
    var elementsToRemove = $(".dynamic-flash").removeClass("visible")
    setTimeout(function(){ elementsToRemove.remove() }, 1000)
  },
  linkTooltips: function() {
    $("a[data-title]").tooltip({ 
      items: 'a',
      track: true,
      classes: { "ui-tooltip": "report-info" },
      content: function() {
        var info = $(this).data('info');
        var reportTitle = $(this).data('title');
        if (info) {
          return `<h2>${reportTitle}</h2><p>${info}</p>`  
        } else {
          return `<h2>${reportTitle}</h2>`
        }
        
    }});
  },
  pivotalTooltips: function() {
    $("#pivotal_stories svg[title]").tooltip({ 
      items: 'svg', 
      track: true,
      classes: { "ui-tooltip": "pivotal-info" },
      content: function() {
        var info = $(this).attr('info');
        var title = $(this).attr('title');
        return `<h4>${title}</h4><pre>${info}</pre>`
    }});

    $("#pivotal_stories div[title]").tooltip({ 
      items: 'svg', 
      track: true,
      classes: { "ui-tooltip": "pivotal-info" },
      content: function() {
        var info = $(this).attr('info');
        var title = $(this).attr('title');
        return `<h4>${title}</h4>`
    }});

    
  },
  initiateGauges: function() {
    //console.log("hey! hey eey!")
    var docStyle = getComputedStyle(document.documentElement);
    $("canvas.gauge").each(function(index, element){
      var target = element // your canvas element
      var maxValue = 100;
      var zones = []
      if($(target).data("value") > 100) {
        maxValue = $(target).data("value")
      }
      // Check for favorable-High vs favorable-Low or look for redmax, yellowmax vs. yellowmin, redmin etc.
      if($(target).data("redmax")) {
        zones = [
          {strokeStyle: docStyle.getPropertyValue('--copaRed'), min: 0, max: $(target).data("redmax")},
          {strokeStyle: docStyle.getPropertyValue('--copaYellow'), min: $(target).data("redmax"), max: $(target).data("yellowmax")},
          {strokeStyle: docStyle.getPropertyValue('--copaGreen'), min: $(target).data("yellowmax"), max: maxValue}
        ]
      } else {
        zones = [
          {strokeStyle: docStyle.getPropertyValue('--copaGreen'), min: 0, max: $(target).data("yellowmin")},
          {strokeStyle: docStyle.getPropertyValue('--copaYellow'), min: $(target).data("yellowmin"), max: $(target).data("redmin")},
          {strokeStyle: docStyle.getPropertyValue('--copaRed'), min: $(target).data("redmin"), max: maxValue}
        ]
      }
      
      var opts = {
        angle: -0.01, // The span of the gauge arc
        lineWidth: 0.2, // The line thickness
        radiusScale: 1, // Relative radius
        staticZones: zones,
        pointer: {
          length: 0.59, // // Relative to gauge radius
          strokeWidth: 0.038, // The thickness
          color: docStyle.getPropertyValue('--gaugeNeedle') // Fill color
        },
        limitMax: false,     // If false, max value increases automatically if value > maxValue
        limitMin: false,     // If true, the min value of the gauge will be fixed
        colorStart: '#6FADCF',   // This would be render a gradient if staticZones
        colorStop: '#8FC0DA',    // weren't populated
        strokeColor: '#E0E0E0',
        generateGradient: true,
        highDpiSupport: true,     // High resolution support
        
      };
      
      var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
      gauge.maxValue = 100; // set max gauge value
      gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
      gauge.animationSpeed = 32; // set animation speed (32 is default value)
      gauge.set($(target).data("value")); // set actual value
    });
  }
};




var globalReady = function() {
  var docWidth = document.documentElement.offsetWidth;
  $('select.select2').not("tag-style").select2({ placeholder: { id: '-1', text: 'Select' } });
  $("select.select2.tag-style").select2({ placeholder: "Select or Enter New", tags: true });

  //[].forEach.call(
  //  document.querySelectorAll('*'),
  //  function(el) {
  //    if (el.offsetWidth > docWidth) {
  //      //console.log(el);
  //    }
  //  }
  //);

  $("tbody.sortable[data-sorturl]").sortable({
    items: '> tr',
    handle: '.sort-handle',
    placeholder: 'placeholder',
    helper: 'clone',
    update: function() {
      $.post($(this).data("sorturl"), $(this).sortable('serialize'))
    }
  })

  $("svg[title]").tooltip({ 
    items: 'svg', 
    track: true,
    classes: { "ui-tooltip": "general-info" },
    content: function() {
      var info = $(this).attr('info');
      var title = $(this).attr('title');
      return `<h2>${title}</h2><p>${info}</p>`
  }});

  $("svg.icon_info-bubble").tooltip({ 
    items: 'svg', 
    track: true,
    classes: { "ui-tooltip": "report-info" },
    content: function() {
      var info = $(this).data('info');
      var reportTitle = $(this).data('title');
      return `<h2>${reportTitle}</h2><p>${info}</p>`
  }});
  Application.pivotalTooltips();
  Application.linkTooltips();
  Application.initiateGauges();
  

  //$( document ).tooltip({ track: true });

  $.datepicker.setDefaults({dateFormat: 'yy-mm-dd'});
  $("input[type=text].datepicker").datepicker({dateFormat: 'yy-mm-dd'});

  // removes the underscore parameter from $.get requests
  // https://stackoverflow.com/questions/3687729/who-add-single-underscore-query-parameter
  $.ajaxSetup({ cache:true })

  //console.log('globalReady');
  $("#head-flash").not('no-click').on('click','div', function() { 
    $("#head-flash").stop().animate({ opacity: 0, height: '0' }, 500, null, function(){ $("#head-flash").empty() });
  });
  
  $(".footer-wrap").hugBottom( { content: $('.inner.content'), header: $(".header-wrap") })  
  
  $('.inner.content').on("ajax:beforeSend", "a[data-remote]:not(form a, .skip-spinner)", function(e, data, status, xhr){
    $(this).append('<span class="loading"><svg width="16" height="16" viewBox="0 -1 24 24"><use xlink:href="#spinner"></use></svg></span>')
  }).on("ajax:complete", "a[data-remote]", function(e, data, status, xhr){
    $("span.loading").remove();
  });

  $('.inner.content').on("ajax:beforeSend", "form[data-remote][method=get]", function(e, data, status, xhr){  
    $(this).find(".loading").css('visibility','visible');
    $(this).find(".icon_search").css('visibility','hidden');
  }).on("ajax:complete", "form[data-remote][method=get]", function(e, data, status, xhr){
    $(this).find(".loading").css('visibility','hidden');
    $(this).find(".icon_search").css('visibility','visible');
  });

  $('.inner.content').on("ajax:beforeSend", "form[data-remote][method=post]", function(e, data, status, xhr){  
    //console.log(e)
    //window.lastCLicEvent = e
    
    if(e.target.nodeName == "A") {
      $(this).find(".cancel-link").hide().after('<span class="loading"><svg width="16" height="16" viewBox="0 -1 24 24"><use xlink:href="#spinner"></use></svg></span>');
    } else {
      $(this).find(".cancel-link").hide()
      $(this).find("input[type=submit]").hide().after('<span class="loading"><svg width="16" height="16" viewBox="0 -1 24 24"><use xlink:href="#spinner"></use></svg> Saving...</span>')  
    }

    
  }).on("ajax:complete", "form[data-remote][method=post]", function(e, data, status, xhr){
    $(this).find("input[type=submit]").show()
    $(this).find("span.loading").remove();
  });

  $("body").on('click', 'div.dynamic-flash', function(e){
    Application.hideFlash()
  })
  setTimeout(function(){ $(".dynamic-flash").addClass("visible"); }, 10);

  $("a[data-copy-from]").click(function(e) {
    e.preventDefault();
    var s = $(this).data('copy-from');
    $(s)[0].select();
    document.execCommand("copy");
    $(this).parent().html('<svg width="16" height="16" viewBox="0 -2 24 24"><use xlink:href="#icon-checkmark"></use></svg>COPIED!');
    $('<textarea></textarea>').select();
    return false;
  });

  $("body").on('click', '.js-print-page', function(e){
    e.preventDefault();
    window.print();
    return false;
  })

  $("body").on('click', '.js-toggler', function(e) {
      e.preventDefault();
      let selector = $(this).data('show-selector');
      $('.js-toggleable').hide();
      $(selector).fadeIn(100);
      $(this).addClass("active").siblings().removeClass("active");
      
      localStorage.setItem(window.location.pathname + "-tabSelector", selector);
      //console.log('setting: ' + window.location.pathname + "-tabSelector" + 'to ' + selector)
  });

  var tabSelector = localStorage.getItem(window.location.pathname + "-tabSelector") || false
  if (tabSelector) {
    //$('.js-toggleable').hide();
    //$(tabSelector).fadeIn(100);
    //$(tabSelector).addClass("active").siblings().removeClass("active");
    $(".js-toggler[data-show-selector='" + tabSelector + "']").click();
  } else {
    $(".js-toggler.auto-click:first").click()  
  }

  if (window.location.pathname.search('copa_pregnancies') === -1) {
    localStorage.removeItem("selectedTab");
  }

  $(".js-toggler").click(function(e) {
    e.preventDefault();
    if (e.isTrigger === undefined) {
      if ($(".auto-search").val() === '' && window.location.href.includes('page') )
      {
        window.location.href = window.location.href.split('page')[0]+'page=1'
      }
    }
  });

  $("a").each(function(){
    if($(this).attr('href') == window.location.pathname) {
      $(this).addClass('active')
    }
  });

  $("body").on('click', '.js-direct-toggler', function(e) {
      e.preventDefault();
      let showSelector = $(this).data('show-selector');
      let hideSelector = $(this).data('hide-selector');
      $(hideSelector).hide();
      $(showSelector).fadeIn(100);
  });

  if($(".auto-submit").length > 0) {
    $(".auto-submit").change(function() {
      var form = $(this).parents('form')[0];
      global.Rails.fire(form,'submit')
    })
  }

  if($(".auto-search").length > 0) {
    var autoSearchTimeout = null;

    $(".auto-search").keyup(function() {
      clearTimeout(autoSearchTimeout);
      var form = $(this).parents('form')[0];
      autoSearchTimeout = setTimeout(function () {
        global.Rails.fire(form,'submit')
      }, 500);
    });
  }

};


//$(document).on('select2:open', function(element) { setTimeout(function() { $('.select2-search__field').focus(); console.log("focused?") }, 100) });

$(document).on('select2:open', () => {
    let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
    allFound[allFound.length - 1].focus();
});

document.addEventListener("turbolinks:load", function() {
  if($("img.stretchy-pants").length > 0) {
    $("img.stretchy-pants").stretchyPants()
  }
})


$(document).on('turbolinks:before-cache', function() {     
  // this approach corrects the select 2 to be duplicated when clicking the back button.
  $('select.select2,.js-member-search').select2('destroy');
});

$(document).on('turbolinks:load', globalReady);

// set previous page URL
document.addEventListener("turbolinks:load", function() {
  localStorage.setItem("visitedUrl", window.location.href);
  if (!localStorage.getItem('visitedUrl').split("/").includes("tool_requests")) {
    localStorage.setItem('currentTab', "report_request")
  }
})

